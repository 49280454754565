import React from "react";
import Helmet from "react-helmet";

import Layout from "src/layout";
import config from "data/SiteConfig";

// eslint-disable-next-line react/prefer-stateless-function
class ThanksPage extends React.Component {
	render() {
		return (
			<Layout>
				<main>
					<Helmet title={`Thanks | ${config.siteTitle}`} />
				</main>
				<div className="container">
					<div className="columns">
						<div className="column is-half">
							<h2>Thanks for getting in touch</h2>
							<p>I will email you soon!</p>
						</div>
					</div>
				</div>
			</Layout>
		);
	}
}

export default ThanksPage;
